import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7a492418"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "prepend-text" }
const _hoisted_2 = {
  key: 1,
  class: "body-text-regular-14 lh-20 distinct--text mt-2px"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_stylized_text = _resolveComponent("tm-stylized-text")!
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_field = _resolveComponent("tm-field")!
  const _component_tm_form_line = _resolveComponent("tm-form-line")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_tm_modal = _resolveComponent("tm-modal")!

  return (_openBlock(), _createBlock(_component_tm_modal, {
    id: _ctx.modalId,
    size: "small"
  }, {
    "modal-content": _withCtx(() => [
      (_ctx.description)
        ? (_openBlock(), _createBlock(_component_tm_stylized_text, {
            key: 0,
            class: "body-text-regular-14 lh-20 mb-4 break",
            text: _ctx.description
          }, null, 8, ["text"]))
        : _createCommentVNode("", true),
      _createVNode(_component_tm_form_line, { label: _ctx.label }, {
        default: _withCtx(() => [
          _createVNode(_component_tm_field, {
            modelValue: _ctx.val,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.val) = $event)),
            placeholder: _ctx.placeholder,
            message: _ctx.message,
            type: _ctx.type,
            validators: _ctx.validators,
            autofocus: ""
          }, _createSlots({ _: 2 }, [
            (_ctx.prependText)
              ? {
                  name: "prepend",
                  fn: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.prependText), 1)
                  ])
                }
              : undefined,
            (_ctx.appendIcon)
              ? {
                  name: "append",
                  fn: _withCtx(() => [
                    _createVNode(_component_tm_icon, {
                      name: _ctx.appendIcon,
                      class: _normalizeClass(_ctx.appendIconClass),
                      size: "xLarge"
                    }, null, 8, ["name", "class"])
                  ])
                }
              : undefined
          ]), 1032, ["modelValue", "placeholder", "message", "type", "validators"])
        ]),
        _: 1
      }, 8, ["label"]),
      (_ctx.hint)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.hint), 1))
        : _createCommentVNode("", true)
    ]),
    "modal-footer-button": _withCtx(() => [
      _createVNode(_component_tm_button, {
        size: "large",
        color: _ctx.btnColor,
        disabled: _ctx.type === 'password' && !_ctx.val
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.btnText), 1)
        ]),
        _: 1
      }, 8, ["color", "disabled"])
    ]),
    _: 1
  }, 8, ["id"]))
}