
import type { PropType } from 'vue'
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmStylizedText from '@/components/shared/TmStylizedText.vue'
import type { ModalNames } from '@/components/modals/types'
import type { TextArray, ButtonColor } from '@/definitions/shared/types'

export default defineComponent({
  components: {
    TmModal,
    TmButton,
    TmFormLine,
    TmStylizedText,
  },
  props: {
    modalId: {
      type: String as PropType<ModalNames>,
      default: 'singleInput',
    },
    prependText: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    btnColor: {
      type: String as PropType<ButtonColor>,
      default: 'blue',
    },
    message: {
      type: String,
    },
    description: {
      type: [String, Array] as PropType<string | TextArray[]>,
    },
    btnText: {
      type: String,
      default: 'Save',
    },
    appendIcon: {
      type: String,
    },
    appendIconClass: {
      type: String,
    },
    label: {
      type: String,
    },
    value: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    hint: {
      type: String,
    },
    validators: {
      type: String,
    },
  },
  setup(props, context) {
    const hasDescriptionSlot = !!context.slots.description
    const val = ref<string>(props.value)

    return {
      hasDescriptionSlot,
      val,
    }
  },
})
